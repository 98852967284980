// Will not work with nested tabs!!!
export const tabs = (scopeNode) => {
  const tabNodes = scopeNode.getElementsByClassName('tab')
  const tabLinkNodes = scopeNode.getElementsByClassName('tab-link')

  const switchToTab = (tabLink) => {
    const tabName = tabLink.dataset.tab
    for(let i = 0; i < tabNodes.length; i++) {
      const tab = tabNodes[i]
      if (tab.classList.contains(tabName)) {
        tab.classList.remove('hidden')
      } else {
        tab.classList.add('hidden')
      }
    }

    for(let i = 0; i < tabLinkNodes.length; i++) {
      const tli = tabLinkNodes[i]
      if(tabLink === tli) {
        tli.classList.add('border-green-600')
        tli.classList.add('text-grey-900')
        tli.classList.remove('border-grey-150')
        tli.classList.remove('text-grey-600')
      } else {
        tli.classList.remove('border-green-600')
        tli.classList.remove('text-grey-900')
        tli.classList.add('border-grey-150')
        tli.classList.add('text-grey-600')
      }
    }
  }

  for (let i = 0; i < tabLinkNodes.length; i++) {
    const tabLink = tabLinkNodes[i]
    tabLink.addEventListener('click', (e) => switchToTab(tabLink))
  }

  if (tabLinkNodes[0]) {
    switchToTab(tabLinkNodes[0])
  }
}

export default tabs
