import '../../config/sentry'
import tabs from './public/tabs'

const init = () => {
  const tabsList = document.getElementsByClassName('tabs')
  for (let i = 0; i < tabsList.length; i++) {
    tabs(tabsList[i])
  }
}

document.body.onload = init
